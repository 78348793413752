import * as React from 'react';
import { StateInterface } from 'Interfaces/StateInterface';
import { connect } from 'react-redux';
import { Dropdown, Form } from 'react-bootstrap';
import { Dispatch } from 'redux';
import { ChartModel } from 'Interfaces/ChartInterface';
import { selectSingleInstanceChart, unSelectSingleInstanceChart } from '../../actions/chartsActions';

interface StateProps {
  charts: ChartModel[];
  selectCharts: ChartModel['id'][];
}

interface ActionProps {
  selectChart: (chartId: string) => void;
  unselectChart: (chartId: string) => void;
}

interface Props {
  formId: string;
}

type SingleInstanceChartsProps = StateProps & ActionProps & Props;

export const SingleInstanceCharts = (props: SingleInstanceChartsProps) => {

  const onChartSelected = (e) => {
    if (e.target.checked) {
      props.selectChart(e.target.value);
    } else {
      props.unselectChart(e.target.value);
    }
  };

  return props.charts.length > 0 ? (
    <Dropdown className="chart-content-spacing single-instance-chart-selector">
      <Dropdown.Toggle variant="primary" id="single-instance-chart-dropdown" size="sm">
        <i className="fa fa-pie-chart" />
      </Dropdown.Toggle>
      <Dropdown.Menu rootCloseEvent="click" className="chart-questions-drop-down">
        {
          props.charts.map(chart => {
            return (
              <Dropdown.Item as="div" key={`single-instance-chart-selector-${chart.id}`}>
                <Form.Check
                  id={`chart-settings-down-checkbox-combine-numeric-fields`}
                  type={'checkbox'}
                  label={chart.name}
                  onChange={onChartSelected}
                  name={'combinenumericfields'}
                  value={chart.id}
                  checked={props.selectCharts.indexOf(`${chart.id}`) !== -1}
                />
              </Dropdown.Item>
            );
          })
        }
      </Dropdown.Menu>
    </Dropdown>
  ) : null;
};

const mapStateToProps = (state: StateInterface, props: Props): StateProps =>  {
  return {
    charts: state.chartsMenu.charts.filter(c => c.formId === props.formId),
    selectCharts: state.chartsMenu.selectedSingleInstanceCharts
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps =>  {
  return {
    selectChart: (chartId) => dispatch(selectSingleInstanceChart(chartId)),
    unselectChart: (chartId) => dispatch(unSelectSingleInstanceChart(chartId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleInstanceCharts);
