import { CollectionsInterface } from './CollectionsInterface';
export const undefinedLocationKey = '0ul0';
export interface LocationInterface {
  key: string;
  title: string;
  parent: string;
  center?: string;
  locationcode?: string;
  lowerPostalCode?: string[];
  upperPostalCode?: string[];
  zoom?: string;
  level?: string;
  isFolder?: boolean;
  isLazy?: boolean;
  loaded?: boolean;
  identifier?: string;
}

export type Locations = LocationInterface[];

export type LocationsInterface = CollectionsInterface<LocationInterface>;
