import * as React from 'react';
import { connect } from 'react-redux';
import { DataPoint } from '../../../../Interfaces/DataPoint';
import { StateInterface } from '../../../../Interfaces/StateInterface';
import ReportsButton from '../ReportsButton';
import { FormInterface } from '../../../../Interfaces/Forms/FormsInterface';

interface Props {
  dataPoint: DataPoint;
  model: FormInterface;
  forms: FormInterface[];
}

interface StateProps {
  reports?: any;
}

export type ReportsButtonProps = Props & StateProps;

const ReportsButtonContainer = (props: ReportsButtonProps) => {
  return (
    <ReportsButton {...props} />
  );
};

/**
 * Function to filter single instance reports.
 */
const filterSingleInstanceReport = (reports) => {
  return reports.filter(report => {
    return report.format === 'Excel' &&
      (report.report_type === 'Table' || report.report_type === 'Cross Table');
  });
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    reports: filterSingleInstanceReport(state.reports)
  };
};

export default connect(mapStateToProps, null)(ReportsButtonContainer);
