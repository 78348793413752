import * as React from 'react';
import { connect } from 'react-redux';
import { LooseObject } from '../../../../Interfaces/LooseObject';
import { DataPoint } from '../../../../Interfaces/DataPoint';
import CommentButton from '../CommentButton';
import { deleteComment, saveComment  } from '../../../../actions/pois';

export interface IExternalProps {
  dataPoint: DataPoint;
}

interface ActionProps {
  saveComment: (id: string, userName: string, comment: LooseObject) => Promise<Response>;
  deleteComment: (id: string) => void;
}

export type CommentButtonProps = IExternalProps & ActionProps;

const CommentsButtonContainer = (props: CommentButtonProps) => {
  return (
    <CommentButton {...props} />
  );
};

const mapDispatchToProps = dispatch => {
  return {
    saveComment: (id: string, userName: string, comment: LooseObject) => {
      return dispatch(saveComment(id, userName, comment));
    },
    deleteComment: (id: string) => {
      dispatch(deleteComment(id));
    }
  };
};

export default connect<undefined, ActionProps, IExternalProps>(null, mapDispatchToProps)(CommentsButtonContainer);
