import './App.scss';
import 'font-awesome/scss/font-awesome.scss';
import * as React from 'react';
import { DataPoint } from '../../Interfaces/DataPoint';
// import SingleInstanceContainer from './Containers/SingleInstanceContainer';
// import { ReactModals } from '../Modals/ReactModals';
// import { ToastContainer } from 'react-toastify';
// import { demoForm } from './demoform';
// import { forms } from './forms';
import { LooseObject } from '../../Interfaces/LooseObject';

interface Props {
  model: LooseObject;
  forms: LooseObject[];
  dataPoint: DataPoint;
  newAnswer: boolean;
  export?: boolean | undefined;
}

export default class App extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <div className={'ReactApp'} />
    );
  }
}
// <ToastContainer position={'bottom-right'} autoClose={false} closeButton={false} hideProgressBar={true} />
