import * as React from 'react';
import { LooseObject } from '../../../Interfaces/LooseObject';

interface Props {
  question: LooseObject;
  parentQuestion: LooseObject;
}

export const SubFormField = (props: Props): JSX.Element | null => {
  if (props.question.hideInSingleInstance) {
    return null;
  }
  return (
    <div className="form-group col-md-12">
      <h3>
        <label>{props.question.text}</label>
        <span className="text-info" >{props.parentQuestion.text}</span>
      </h3>
    </div>
  );
};
