import * as React from 'react';
import { connect } from 'react-redux';
import CalculatedValueQuestion from '../elements/CalculatedValueQuestion';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { Locations } from '../../../Interfaces/LocationInterface';
import FormUtils from '../utils/FormUtils';
import { StateInterface } from '../../../Interfaces/StateInterface';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
// import { StateInterface } from '../../Interfaces/StateInterface';

export interface Props {
  updateAnswer: (value: LooseObject) => void;
  question: LooseObject;
  formUtils: FormUtils;
  dataPoint: DataPoint;
  forms: LooseObject[];
  parentModel?: LooseObject;
  parentDataPoint?: LooseObject;
  parentQuestion?: LooseObject;
  isSubQuestion?: boolean;
}

export interface StateProps {
  locationHierarchy: Locations;
  pois: LooseObject;
  users: LooseObject;
  clientPersist: ClientPersistInterface;
}

export type CalculatedPropsInterface = Props & StateProps;

const CalculatedValueQuestionContainer = (props: CalculatedPropsInterface) => {
  return (
    <CalculatedValueQuestion {...props} />
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    locationHierarchy: state.locations.collection, // read state from parent if there?
    pois: state.answers,
    users: state.users,
    clientPersist: state.clientPersist
  };
};

export default connect(mapStateToProps, {})(CalculatedValueQuestionContainer);
