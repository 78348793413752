import './LoadingComponent.scss';
import * as React from 'react';

export const LoadingComponent = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-inner" />
    </div>
  );
};
